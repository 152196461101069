import _extends from "@babel/runtime/helpers/extends";
import defaultConfig from '../default-config';
import { getQuery } from '../selectors';

var isCacheValid = function isCacheValid(cache) {
  return cache === null || Date.now() <= cache;
};

var getCacheKey = function getCacheKey(action) {
  return action.type + (action.meta.requestKey || '');
};

export default (function (config) {
  if (config === void 0) {
    config = defaultConfig;
  }

  return function (store) {
    return function (next) {
      return function (action) {
        if (config.isRequestAction(action) && action.meta && action.meta.cache && !action.meta.ssrResponse) {
          var cacheKey = getCacheKey(action);
          var state = store.getState();
          var cacheValue = state.requests.cache[cacheKey];

          if (cacheValue !== undefined && isCacheValid(cacheValue)) {
            var query = getQuery(state, {
              type: action.type,
              requestKey: action.meta && action.meta.requestKey
            });
            return next(_extends({}, action, {
              meta: _extends({}, action.meta, {
                cacheResponse: {
                  data: query.data
                }
              })
            }));
          }
        }

        return next(action);
      };
    };
  };
});