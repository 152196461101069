import _extends from "@babel/runtime/helpers/extends";
import { CLEAR_REQUESTS_CACHE } from '../constants';
import { isSuccessAction, getRequestActionFromResponse } from '../actions';

var getNewCacheTimeout = function getNewCacheTimeout(cache) {
  return cache === true ? null : cache * 1000 + Date.now();
};

var getCacheKey = function getCacheKey(action) {
  return action.type + (action.meta.requestKey || '');
};

export default (function (state, action) {
  if (action.type === CLEAR_REQUESTS_CACHE) {
    if (action.cacheKeys.length === 0) {
      return {};
    }

    state = _extends({}, state);
    action.cacheKeys.forEach(function (cacheKey) {
      delete state[cacheKey];
    });
    return state;
  }

  if (isSuccessAction(action) && action.meta.cache && !action.meta.cacheResponse && !action.meta.ssrResponse) {
    var _extends2;

    var requestAction = getRequestActionFromResponse(action);
    return _extends({}, state, (_extends2 = {}, _extends2[getCacheKey(requestAction)] = getNewCacheTimeout(action.meta.cache), _extends2));
  }

  return state;
});