import _extends from "@babel/runtime/helpers/extends";
import defaultConfig from '../default-config';
import { getQuery } from '../selectors';
export default (function (config) {
  if (config === void 0) {
    config = defaultConfig;
  }

  return function (store) {
    return function (next) {
      return function (action) {
        if (!config.isRequestAction(action)) {
          return next(action);
        }

        var state = store.getState();
        var actionsToIgnore = state.requests.ssr;

        if (actionsToIgnore.findIndex(function (v) {
          return v === action.type;
        }) === -1) {
          return next(action);
        }

        var query = getQuery(state, {
          type: action.type,
          requestKey: action.meta && action.meta.requestKey
        });
        return next(_extends({}, action, {
          meta: _extends({}, action.meta, {
            ssrResponse: {
              data: query.data
            }
          })
        }));
      };
    };
  };
});