import _extends from "@babel/runtime/helpers/extends";
import defaultConfig from '../default-config';
import { success, error, abort, isResponseAction, getRequestActionFromResponse, isErrorAction, isSuccessAction } from '../actions';
import updateData from './update-data';
import { normalize, mergeData } from '../normalizers';
import { getQuery } from '../selectors';

var getInitialQuery = function getInitialQuery(normalized) {
  return {
    data: null,
    pending: 0,
    error: null,
    ref: {},
    normalized: normalized,
    usedKeys: normalized ? {} : null
  };
};

var getDataFromResponseAction = function getDataFromResponseAction(action) {
  return action.payload ? action.payload.data : action.response.data;
};

var shouldBeNormalized = function shouldBeNormalized(action, config) {
  return action.meta && action.meta.normalize !== undefined ? action.meta.normalize : config.normalize;
};

var queryReducer = function queryReducer(state, action, actionType, config, normalizedData) {
  if (state === undefined) {
    state = getInitialQuery(shouldBeNormalized(action, config));
  }

  if (action.meta && action.meta.mutations && action.meta.mutations[actionType]) {
    var _queries;

    var mutationConfig = action.meta.mutations[actionType];
    var data = updateData(state.normalized ? getQuery({
      requests: {
        normalizedData: normalizedData,
        queries: (_queries = {}, _queries[actionType] = state, _queries)
      }
    }, {
      type: actionType
    }).data : state.data, action, mutationConfig);
    return data === state.data ? state : _extends({}, state, {
      data: data
    });
  }

  switch (action.type) {
    case success(actionType):
      return _extends({}, state, {
        data: getDataFromResponseAction(action),
        pending: state.pending - 1,
        error: null
      });

    case error(actionType):
      return _extends({}, state, {
        data: null,
        pending: state.pending - 1,
        error: action.payload ? action.payload : action.error
      });

    case abort(actionType):
      return _extends({}, state, {
        pending: state.pending - 1
      });

    default:
      return _extends({}, state, {
        pending: state.pending + 1,
        error: null
      });
  }
};

var maybeGetQueryActionType = function maybeGetQueryActionType(action, config) {
  if (config.isRequestAction(action) && config.isRequestActionQuery(action)) {
    return action.type;
  }

  if (isResponseAction(action) && config.isRequestActionQuery(getRequestActionFromResponse(action))) {
    return getRequestActionFromResponse(action).type;
  }

  return null;
};

var updateNormalizedData = function updateNormalizedData(normalizedData, action, config) {
  if (config.isRequestAction(action) && action.meta && action.meta.optimisticData) {
    var _normalize = normalize(action.meta.optimisticData, config),
        newNormalizedData = _normalize[1];

    return mergeData(normalizedData, newNormalizedData);
  }

  if (isResponseAction(action) && isErrorAction(action) && action.meta.revertedData) {
    var _normalize2 = normalize(action.meta.revertedData, config),
        _newNormalizedData = _normalize2[1];

    return mergeData(normalizedData, _newNormalizedData);
  }

  if (isResponseAction(action) && isSuccessAction(action) && shouldBeNormalized(action, config) && !config.isRequestActionQuery(getRequestActionFromResponse(action))) {
    var _normalize3 = normalize(getDataFromResponseAction(action), config),
        _newNormalizedData2 = _normalize3[1];

    return mergeData(normalizedData, _newNormalizedData2);
  }

  if (action.meta && action.meta.localData) {
    var _normalize4 = normalize(action.meta.localData, config),
        _newNormalizedData3 = _normalize4[1];

    return mergeData(normalizedData, _newNormalizedData3);
  }

  return normalizedData;
};

export default (function (state, action, config) {
  if (config === void 0) {
    config = defaultConfig;
  }

  var normalizedData = updateNormalizedData(state.normalizedData, action, config);

  if (action.meta && action.meta.mutations) {
    return {
      queries: _extends({}, state.queries, {}, Object.keys(action.meta.mutations).filter(function (actionType) {
        return !!state.queries[actionType];
      }).reduce(function (prev, actionType) {
        var updatedQuery = queryReducer(state.queries[actionType], action, actionType, config, normalizedData);

        if (updatedQuery.normalized && updatedQuery.data !== state.queries[actionType].data) {
          var _normalize5 = normalize(updatedQuery.data, config),
              newdata = _normalize5[0],
              newNormalizedData = _normalize5[1],
              usedKeys = _normalize5[2];

          normalizedData = mergeData(normalizedData, newNormalizedData);
          prev[actionType] = _extends({}, updatedQuery, {
            data: newdata,
            usedKeys: usedKeys
          });
        } else {
          prev[actionType] = updatedQuery;
        }

        return prev;
      }, {})),
      normalizedData: normalizedData
    };
  }

  var queryActionType = maybeGetQueryActionType(action, config);

  if (queryActionType) {
    var _extends3;

    var queryType = action.meta && typeof action.meta.requestKey !== 'undefined' ? queryActionType + action.meta.requestKey : queryActionType;
    var updatedQuery = queryReducer(state.queries[queryType], action, queryActionType, config);

    if (updatedQuery.normalized && updatedQuery.data && (!state.queries[queryType] || state.queries[queryType].data !== updatedQuery.data)) {
      var _extends2;

      var _normalize6 = normalize(updatedQuery.data, config),
          data = _normalize6[0],
          newNormalizedData = _normalize6[1],
          usedKeys = _normalize6[2];

      return {
        queries: _extends({}, state.queries, (_extends2 = {}, _extends2[queryType] = _extends({}, updatedQuery, {
          data: data,
          usedKeys: usedKeys
        }), _extends2)),
        normalizedData: mergeData(normalizedData, newNormalizedData)
      };
    }

    return {
      queries: _extends({}, state.queries, (_extends3 = {}, _extends3[queryType] = updatedQuery, _extends3)),
      normalizedData: normalizedData
    };
  }

  return state.normalizedData === normalizedData ? state : _extends({}, state, {
    normalizedData: normalizedData
  });
});