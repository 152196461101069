import _extends from "@babel/runtime/helpers/extends";
import { createSelectorCreator, defaultMemoize } from 'reselect';
import { denormalize, getDependentKeys } from '../normalizers';

var isQueryEqual = function isQueryEqual(currentVal, previousVal) {
  if (currentVal.data !== previousVal.data || currentVal.pending !== previousVal.pending || currentVal.error !== previousVal.error) {
    return false;
  }

  if (currentVal.data === null && (currentVal.multiple !== previousVal.multiple || currentVal.defaultData !== previousVal.defaultData)) {
    return false;
  }

  if (currentVal.normalized && currentVal.normalizedData !== previousVal.normalizedData) {
    var currentDependencies = getDependentKeys(currentVal.data, currentVal.normalizedData, currentVal.usedKeys);
    var previousDependencies = getDependentKeys(previousVal.data, previousVal.normalizedData, previousVal.usedKeys);

    if (currentDependencies.size !== previousDependencies.size) {
      return false;
    }

    for (var _iterator = currentDependencies, _isArray = Array.isArray(_iterator), _i = 0, _iterator = _isArray ? _iterator : _iterator[Symbol.iterator]();;) {
      var _ref;

      if (_isArray) {
        if (_i >= _iterator.length) break;
        _ref = _iterator[_i++];
      } else {
        _i = _iterator.next();
        if (_i.done) break;
        _ref = _i.value;
      }

      var k = _ref;

      if (!previousDependencies.has(k) || currentVal.normalizedData[k] !== previousVal.normalizedData[k]) {
        return false;
      }
    }
  }

  return true;
};

var createCustomSelector = createSelectorCreator(defaultMemoize, isQueryEqual);

var getData = function getData(data, multiple, defaultData) {
  if (data !== null) {
    return data;
  }

  if (defaultData !== undefined) {
    return defaultData;
  }

  if (multiple) {
    return [];
  }

  return data;
};

var getQueryState = function getQueryState(state, type, requestKey) {
  if (requestKey === void 0) {
    requestKey = '';
  }

  return state.requests.queries[type + requestKey];
};

var createQuerySelector = function createQuerySelector(type, requestKey) {
  return createCustomSelector(function (state, defaultData, multiple) {
    // in order not to keep queryState.ref reference in selector memoize
    var _getQueryState = getQueryState(state, type, requestKey),
        data = _getQueryState.data,
        pending = _getQueryState.pending,
        error = _getQueryState.error,
        normalized = _getQueryState.normalized,
        usedKeys = _getQueryState.usedKeys;

    return {
      data: data,
      pending: pending,
      error: error,
      normalized: normalized,
      usedKeys: usedKeys,
      multiple: multiple,
      defaultData: defaultData,
      normalizedData: state.requests.normalizedData
    };
  }, function (_ref2) {
    var data = _ref2.data,
        pending = _ref2.pending,
        error = _ref2.error,
        usedKeys = _ref2.usedKeys,
        normalized = _ref2.normalized,
        normalizedData = _ref2.normalizedData,
        defaultData = _ref2.defaultData,
        multiple = _ref2.multiple;
    return {
      data: normalized ? denormalize(getData(data, multiple, defaultData), normalizedData, usedKeys) : getData(data, multiple, defaultData),
      loading: pending > 0,
      error: error
    };
  });
};

var defaultQuery = {
  data: null,
  loading: false,
  error: null
};

var defaultQueryMultiple = _extends({}, defaultQuery, {
  data: []
});

var defaultQueriesWithCustomData = new Map();

var getDefaultQuery = function getDefaultQuery(defaultData, multiple) {
  if (defaultData !== undefined && defaultQueriesWithCustomData.get(defaultData)) {
    return defaultQueriesWithCustomData.get(defaultData);
  }

  if (defaultData !== undefined) {
    var query = _extends({}, defaultQuery, {
      data: defaultData
    });

    defaultQueriesWithCustomData.set(defaultData, query);
    return query;
  }

  return multiple ? defaultQueryMultiple : defaultQuery;
};

var querySelectors = new WeakMap();
export default (function (state, _ref3) {
  var type = _ref3.type,
      requestKey = _ref3.requestKey,
      defaultData = _ref3.defaultData,
      _ref3$multiple = _ref3.multiple,
      multiple = _ref3$multiple === void 0 ? false : _ref3$multiple;
  var queryState = getQueryState(state, type, requestKey);

  if (!queryState) {
    return getDefaultQuery(defaultData, multiple);
  }

  if (!querySelectors.get(queryState.ref)) {
    querySelectors.set(queryState.ref, createQuerySelector(type, requestKey));
  }

  return querySelectors.get(queryState.ref)(state, defaultData, multiple);
});