export var SUCCESS_SUFFIX = '_SUCCESS';
export var ERROR_SUFFIX = '_ERROR';
export var ABORT_SUFFIX = '_ABORT';
export var REQUESTS_CONFIG = 'REDUX_SAGA_REQUESTS_CONFIG';
export var RUN_BY_INTERCEPTOR = 'RUN_BY_INTERCEPTOR';
export var INTERCEPTORS = {
  ON_REQUEST: 'onRequest',
  ON_ERROR: 'onError',
  ON_SUCCESS: 'onSuccess',
  ON_ABORT: 'onAbort'
};
export var CLEAR_REQUESTS_CACHE = 'CLEAR_REQUESTS_CACHE';
export var RESET_REQUESTS = 'RESET_REQUESTS';
export var INCORRECT_PAYLOAD_ERROR = "Incorrect payload for request action. Action must have form of { type: 'TYPE', request: {} }, { type: 'TYPE', request: [{}, {}] }, { type: 'TYPE', payload: { request: {} } } or { type: 'TYPE', payload: { request: [{}, {}] } }";