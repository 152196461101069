import { setContext } from 'redux-saga/effects';
import defaultConfig from '../default-config';
import { REQUESTS_CONFIG } from '../constants';
export default function createRequestInstance(config) {
  var _setContext;

  if (config === void 0) {
    config = defaultConfig;
  }

  return setContext((_setContext = {}, _setContext[REQUESTS_CONFIG] = config, _setContext));
}