import _extends from "@babel/runtime/helpers/extends";
import { SUCCESS_SUFFIX, ERROR_SUFFIX, ABORT_SUFFIX, CLEAR_REQUESTS_CACHE, RESET_REQUESTS } from './constants';

var getActionWithSuffix = function getActionWithSuffix(suffix) {
  return function (actionType) {
    return actionType + suffix;
  };
};

export var success = getActionWithSuffix(SUCCESS_SUFFIX);
export var error = getActionWithSuffix(ERROR_SUFFIX);
export var abort = getActionWithSuffix(ABORT_SUFFIX);

var isFSA = function isFSA(action) {
  return !!action.payload;
};

export var createSuccessAction = function createSuccessAction(action, response) {
  return _extends({
    type: success(action.type)
  }, isFSA(action) ? {
    payload: response
  } : {
    response: response
  }, {
    meta: _extends({}, action.meta, {
      requestAction: action
    })
  });
};
export var createErrorAction = function createErrorAction(action, errorData) {
  return _extends({
    type: error(action.type)
  }, isFSA(action) ? {
    payload: errorData,
    error: true
  } : {
    error: errorData
  }, {
    meta: _extends({}, action.meta, {
      requestAction: action
    })
  });
};
export var createAbortAction = function createAbortAction(action) {
  return {
    type: abort(action.type),
    meta: _extends({}, action.meta, {
      requestAction: action
    })
  };
};
export var getActionPayload = function getActionPayload(action) {
  return action.payload === undefined ? action : action.payload;
};
export var isRequestAction = function isRequestAction(action) {
  var actionPayload = getActionPayload(action);
  return !!actionPayload && !!actionPayload.request && !!(Array.isArray(actionPayload.request) || actionPayload.request.url || actionPayload.request.query) && !actionPayload.response && !(actionPayload instanceof Error);
};
export var isResponseAction = function isResponseAction(action) {
  return !!(action.meta && action.meta.requestAction);
};
export var getRequestActionFromResponse = function getRequestActionFromResponse(action) {
  return action.meta.requestAction;
};
export var isSuccessAction = function isSuccessAction(action) {
  return isResponseAction(action) && action.type.endsWith(SUCCESS_SUFFIX);
};
export var isErrorAction = function isErrorAction(action) {
  return isResponseAction(action) && action.type.endsWith(ERROR_SUFFIX);
};
export var isAbortAction = function isAbortAction(action) {
  return isResponseAction(action) && action.type.endsWith(ABORT_SUFFIX);
};

var isRequestQuery = function isRequestQuery(request) {
  return !request.query && (!request.method || request.method.toLowerCase() === 'get') || request.query && !request.query.trim().startsWith('mutation');
};

export var isRequestActionQuery = function isRequestActionQuery(action) {
  var _getActionPayload = getActionPayload(action),
      request = _getActionPayload.request;

  if (action.meta && action.meta.asMutation !== undefined) {
    return !action.meta.asMutation;
  }

  return !!(Array.isArray(request) ? request.every(isRequestQuery) : isRequestQuery(request));
};
export var clearRequestsCache = function clearRequestsCache() {
  for (var _len = arguments.length, cacheKeys = new Array(_len), _key = 0; _key < _len; _key++) {
    cacheKeys[_key] = arguments[_key];
  }

  return {
    type: CLEAR_REQUESTS_CACHE,
    cacheKeys: cacheKeys
  };
};
export var resetRequests = function resetRequests(requests) {
  if (requests === void 0) {
    requests = null;
  }

  return {
    type: RESET_REQUESTS,
    requests: requests
  };
};