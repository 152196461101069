import _extends from "@babel/runtime/helpers/extends";
import { fork } from 'redux-saga/effects';
import { requestsReducer } from './reducers';
import { createRequestInstance, watchRequests } from './sagas';
import { createRequestsPromiseMiddleware, createRequestsCacheMiddleware, createClientSsrMiddleware, createServerSsrMiddleware } from './middleware';
import defaultConfig from './default-config';

var defer = function defer() {
  var res;
  var rej;
  var promise = new Promise(function (resolve, reject) {
    res = resolve;
    rej = reject;
  });
  promise.resolve = res;
  promise.reject = rej;
  return promise;
};

var handleRequests = function handleRequests(userConfig) {
  var config = _extends({}, defaultConfig, {}, userConfig);

  var requestsPromise = config.ssr === 'server' ? defer() : null;
  return {
    requestsReducer: requestsReducer(config),
    requestsMiddleware: [config.ssr === 'server' && createServerSsrMiddleware(requestsPromise, config), config.ssr === 'client' && createClientSsrMiddleware(config), config.cache && createRequestsCacheMiddleware(config), config.promisify && createRequestsPromiseMiddleware(config)].filter(Boolean),
    requestsSagas: [createRequestInstance(config), fork(watchRequests)],
    requestsPromise: requestsPromise
  };
};

export default handleRequests;