import _extends from "@babel/runtime/helpers/extends";
import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/objectWithoutPropertiesLoose";
import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { getMutationSelector } from 'redux-saga-requests';
import { reactComponentPropType } from './propTypesValidators';

var Mutation = function Mutation(_ref) {
  var type = _ref.type,
      selector = _ref.selector,
      requestKey = _ref.requestKey,
      children = _ref.children,
      Component = _ref.component,
      extraProps = _objectWithoutPropertiesLoose(_ref, ["type", "selector", "requestKey", "children", "component"]);

  var mutation = useSelector(selector || getMutationSelector({
    type: type,
    requestKey: requestKey
  }));

  if (children) {
    return children(mutation);
  }

  return React.createElement(Component, _extends({
    mutation: mutation
  }, extraProps));
};

Mutation.propTypes = process.env.NODE_ENV !== "production" ? {
  selector: PropTypes.func,
  type: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  requestKey: PropTypes.string,
  children: PropTypes.func,
  component: reactComponentPropType('Mutation')
} : {};
export default Mutation;