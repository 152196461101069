import _extends from "@babel/runtime/helpers/extends";
import axios from 'axios';
export var createDriver = function createDriver(axiosInstance) {
  return function (requestConfig) {
    var abortSource = axios.CancelToken.source();
    var responsePromise = axiosInstance(_extends({
      cancelToken: abortSource.token
    }, requestConfig)).then(function (response) {
      return {
        data: response.data
      };
    });

    responsePromise.cancel = function () {
      return abortSource.cancel();
    };

    return responsePromise;
  };
};