import defaultConfig from '../default-config';
import { getRequestActionFromResponse, isResponseAction, isSuccessAction } from '../actions';
export default (function (state, action, config) {
  if (state === void 0) {
    state = [];
  }

  if (config === void 0) {
    config = defaultConfig;
  }

  if (config.ssr === 'server' && isResponseAction(action) && isSuccessAction(action)) {
    return [].concat(state, [getRequestActionFromResponse(action).type]);
  }

  if (config.ssr === 'client' && config.isRequestAction(action) && action.meta && action.meta.ssrResponse) {
    var indexToRemove = state.findIndex(function (v) {
      return v === action.type;
    });

    if (indexToRemove >= 0) {
      return state.filter(function (_, i) {
        return i !== indexToRemove;
      });
    }
  }

  return state;
});