import _extends from "@babel/runtime/helpers/extends";
import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/objectWithoutPropertiesLoose";
import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { getQuerySelector } from 'redux-saga-requests';
import { reactComponentPropType } from './propTypesValidators';

var Query = function Query(_ref) {
  var type = _ref.type,
      requestKey = _ref.requestKey,
      selector = _ref.selector,
      defaultData = _ref.defaultData,
      multiple = _ref.multiple,
      children = _ref.children,
      Component = _ref.component,
      isDataEmpty = _ref.isDataEmpty,
      showLoaderDuringRefetch = _ref.showLoaderDuringRefetch,
      noDataMessage = _ref.noDataMessage,
      ErrorComponent = _ref.errorComponent,
      errorComponentProps = _ref.errorComponentProps,
      LoadingComponent = _ref.loadingComponent,
      loadingComponentProps = _ref.loadingComponentProps,
      extraProps = _objectWithoutPropertiesLoose(_ref, ["type", "requestKey", "selector", "defaultData", "multiple", "children", "component", "isDataEmpty", "showLoaderDuringRefetch", "noDataMessage", "errorComponent", "errorComponentProps", "loadingComponent", "loadingComponentProps"]);

  var query = useSelector(selector || getQuerySelector({
    type: type,
    requestKey: requestKey,
    defaultData: defaultData,
    multiple: multiple
  }));
  var dataEmpty = isDataEmpty(query);

  if (query.loading && (showLoaderDuringRefetch || dataEmpty)) {
    return LoadingComponent ? React.createElement(LoadingComponent, loadingComponentProps) : null;
  }

  if (query.error) {
    return ErrorComponent ? React.createElement(ErrorComponent, _extends({
      error: query.error
    }, errorComponentProps)) : null;
  }

  if (dataEmpty) {
    return noDataMessage;
  }

  if (children) {
    return children(query);
  }

  return React.createElement(Component, _extends({
    query: query
  }, extraProps));
};

Query.defaultProps = {
  isDataEmpty: function isDataEmpty(query) {
    return Array.isArray(query.data) ? query.data.length === 0 : !query.data;
  },
  showLoaderDuringRefetch: true,
  noDataMessage: null,
  multiple: false
};
Query.propTypes = process.env.NODE_ENV !== "production" ? {
  type: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  requestKey: PropTypes.string,
  selector: PropTypes.func,
  multiple: PropTypes.bool,
  defaultData: PropTypes.any,
  children: PropTypes.func,
  component: reactComponentPropType('Query'),
  isDataEmpty: PropTypes.func,
  showLoaderDuringRefetch: PropTypes.bool,
  noDataMessage: PropTypes.node,
  errorComponent: reactComponentPropType('Query'),
  errorComponentProps: PropTypes.objectOf(PropTypes.any),
  loadingComponent: reactComponentPropType('Query'),
  loadingComponentProps: PropTypes.objectOf(PropTypes.any)
} : {};
export default Query;