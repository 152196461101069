import defaultConfig from '../default-config';
import { success, isResponseAction, getRequestActionFromResponse } from '../actions';

var shouldActionBePromisified = function shouldActionBePromisified(action, autoPromisify) {
  return autoPromisify && !(action.meta && action.meta.asPromise === false) || action.meta && action.meta.asPromise;
};

export default (function (config) {
  if (config === void 0) {
    config = defaultConfig;
  }

  var requestMap = new Map();
  return function () {
    return function (next) {
      return function (action) {
        if (config.isRequestAction(action) && shouldActionBePromisified(action, config.autoPromisify)) {
          return new Promise(function (resolve, reject) {
            requestMap.set(action, function (response, error) {
              return error ? reject(response) : resolve(response);
            });
            next(action);
          });
        }

        if (isResponseAction(action)) {
          var requestAction = getRequestActionFromResponse(action);

          if (shouldActionBePromisified(requestAction, config.autoPromisify)) {
            var requestActionPromise = requestMap.get(requestAction);
            requestActionPromise(action, action.type !== success(requestAction.type));
            requestMap["delete"](requestAction);
          }
        }

        return next(action);
      };
    };
  };
});