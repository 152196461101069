import { END } from 'redux-saga';
import defaultConfig from '../default-config';
import { isResponseAction, isSuccessAction } from '../actions';
export default (function (requestsPromise, config) {
  if (config === void 0) {
    config = defaultConfig;
  }

  var index = 0;
  var serverSuccessActions = [];
  return function (store) {
    return function (next) {
      return function (action) {
        if (config.isRequestAction(action)) {
          index += action.meta && action.meta.dependentRequestsNumber !== undefined ? action.meta.dependentRequestsNumber + 1 : 1;
        } else if (isResponseAction(action)) {
          if (!isSuccessAction(action)) {
            store.dispatch(END);
            requestsPromise.reject(action);
            return next(action);
          }

          serverSuccessActions.push(action);
          index -= action.meta.isDependentRequest ? 2 : 1;

          if (index === 0) {
            store.dispatch(END);
            requestsPromise.resolve(serverSuccessActions);
          }
        }

        return next(action);
      };
    };
  };
});