import _extends from "@babel/runtime/helpers/extends";
import { RESET_REQUESTS } from '../constants';

var getRequestTypeString = function getRequestTypeString(requestType) {
  return typeof requestType === 'function' ? requestType.toString() : requestType;
};

var getKeys = function getKeys(requests) {
  return requests.map(function (v) {
    return typeof v === 'object' ? getRequestTypeString(v.requestType) + v.requestKey : getRequestTypeString(v);
  });
};

var resetQuery = function resetQuery(query) {
  return _extends({}, query, {
    data: null,
    error: null,
    usedKeys: query.normalized ? {} : null
  });
};

var resetMutation = function resetMutation(mutation) {
  return _extends({}, mutation, {
    error: null
  });
}; // TODO: this should be rewritten to more functional style, we need things like filter/map object helpers


export default (function (state, action) {
  if (action.type !== RESET_REQUESTS) {
    return state;
  }

  var queries = state.queries,
      mutations = state.mutations,
      cache = state.cache;
  var clearAll = !action.requests;
  var keys = !clearAll && getKeys(action.requests);
  queries = Object.entries(queries).reduce(function (prev, _ref) {
    var k = _ref[0],
        v = _ref[1];

    if (clearAll || keys.includes(k)) {
      prev[k] = resetQuery(v);
    } else {
      prev[k] = v;
    }

    return prev;
  }, {});
  mutations = Object.entries(mutations).reduce(function (prev, _ref2) {
    var k = _ref2[0],
        v = _ref2[1];

    if (clearAll || keys.includes(k)) {
      prev[k] = resetMutation(v);
    } else {
      prev[k] = v;
    }

    return prev;
  }, {});
  cache = clearAll ? {} : Object.entries(cache).reduce(function (prev, _ref3) {
    var k = _ref3[0],
        v = _ref3[1];

    if (keys.includes(k)) {
      return prev;
    }

    prev[k] = v;
    return prev;
  }, {});
  return {
    queries: queries,
    mutations: mutations,
    cache: cache
  };
});